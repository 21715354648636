import { useEffect, useRef } from "react";

export const useStopwatch = () => {
  const secondsRef = useRef(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      secondsRef.current += 1;
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return { seconds: secondsRef.current };
};
