import React, { useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import { AppButton, AppLoadingButton } from "../../ui";
import { PREPAYMENT_VIEW_TYPES, prePaymentViews } from "../../../content/static/prePayment";
import { useCounter } from "../../../hooks/useCounter";
import { laptopSize, mobileSize } from "../../../util/variables";
import {
  web_acq_submit_answer,
  web_acq_question_view,
} from "../../../services/mixpanel/acquisition-events";

import { AnimationVariant, TextVariant, PrePaymentVariant } from "./variants";
const preparePrePaymentViews = () => {
  const initialViews = [...prePaymentViews];
  return initialViews;
};

export const VariantPrepaymentComponent = ({
  handleRedirect,
  loading,
}: {
  handleRedirect: () => void;
  loading: boolean;
}) => {
  const { counter, increment } = useCounter();

  const prePaymentViews = preparePrePaymentViews();

  const [paymentData, setPaymentData] = React.useState(prePaymentViews[0]);

  useEffect(() => {
    if (!paymentData) {
      return;
    }

    web_acq_question_view(paymentData.id);
  }, [paymentData]);

  const nextPaymentView = () => {
    web_acq_submit_answer(paymentData.id, prePaymentViews.id as any);
    setPaymentData(prePaymentViews[counter + 1]);
    increment();
  };

  const renderPaymentView = useCallback(() => {
    switch (paymentData.type) {
      case PREPAYMENT_VIEW_TYPES.Animation:
        return <AnimationVariant title={paymentData.title} asset={paymentData.asset} />;
      case PREPAYMENT_VIEW_TYPES.Text:
        return <TextVariant title={paymentData.title} />;
      default:
        return <div>default</div>;
    }
  }, [paymentData]);

  const isLastView = counter + 1 > prePaymentViews.length;

  return (
    <Wrapper>
      <VariantPrepaymentComponentWrapper>
        {isLastView ? <PrePaymentVariant /> : <div>{renderPaymentView()}</div>}
      </VariantPrepaymentComponentWrapper>
      <AppLoadingButton
        type="button"
        disabled={loading}
        loading={loading}
        onClick={isLastView ? handleRedirect : nextPaymentView}
      >
        {isLastView ? "Start Your 7-Day Free Trial" : "Continue"}
      </AppLoadingButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px;
  @media ${laptopSize} {
    padding: 64px;
  }
  @media ${mobileSize} {
    padding: 64px 24px;
  }
`;

const VariantPrepaymentComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  margin: 0 auto;
`;
