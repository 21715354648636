import React from "react";
import styled from "styled-components";
import { AppText, FlexDiv } from "../../../ui";
import { LottieVideo } from "../../Segments";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";

interface AnimationVariantProps {
  title?: string;
  asset: string;
}

const AnimationVariant: React.FC<AnimationVariantProps> = ({ title, asset }) => {
  return (
    <FlexDiv direction="column" align="center" style={{ width: "100%" }}>
      {title && <Text dangerouslySetInnerHTML={{ __html: title }} />}
      <AnimWrapper hasText={Boolean(title)}>
        <LottieVideo loop lottieJSON={asset} />
      </AnimWrapper>
    </FlexDiv>
  );
};

const AnimWrapper = styled(FlexDiv as any).attrs({
  align: "center",
  justify: "center",
})<{ hasText: boolean; hardcode: boolean }>`
  max-width: 420px;
  height: ${props => (props.hasText ? 53 : 60)}dvh;
  max-height: 414px;
  margin-top: ${props => (props.hardcode ? -32 : 0)}px;
  @media ${mobileSize} {
    max-height: 270px;
  }
`;

const Text = styled(AppText as any)`
  font-size: 36px;
  line-height: 44px;
  max-width: 468px;
  min-height: 84px;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  b {
    color: ${theme.BLACK_COLOR};
  }

  @media ${mobileSize} {
    font-size: 32px;
    line-height: 38px;
  }
`;

export default AnimationVariant;
