import React from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import {
  SpecialOfferBar,
  HeroSegment,
  AppPitchSegment,
  TestimonySegment,
} from "../components/PageSegments/special-offer/landing";
import { BottomNavSegment } from "../components/PageSegments/BottomNav";
import SpecialOfferModal from "../components/Modal/SpecialOfferModal";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { fbq } from "../services/pixel";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";

export const WEB_NO_TRIAL_FLOW = "web_no_trial_flow";

export default () => {
  const { setOnboardingLandingPage } = useOnboardingContext();

  React.useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage(WEB_NO_TRIAL_FLOW);
    setMixpanelProperties({
      $LandingPage: WEB_NO_TRIAL_FLOW,
    });
    sessionStorage.setItem("landing_page", WEB_NO_TRIAL_FLOW);
  }, [setOnboardingLandingPage]);

  return (
    <BasicLayout>
      <main>
        <SpecialOfferBar trial={false} />
        <HeroSegment />
        <TestimonySegment />
        <AppPitchSegment />
        <BottomNavSegment />
        <SpecialOfferModal />
      </main>
    </BasicLayout>
  );
};
