import React, { useContext } from "react";
import styled from "styled-components";
import { AppText } from "../../../ui";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { useOnboardingContext } from "../../onboarding/OnboardingContext";
import { LocalPriceContext } from "../../../../providers/LocalPriceProvider";

const PrePaymentVariant: React.FC<PrePaymentVariantProps> = () => {
  const { customGroup } = useOnboardingContext();
  const { currentPrice } = useContext(LocalPriceContext);
  return (
    <PrePaymentVariantContainer>
      <DiscountText>{`${customGroup.toUpperCase()} DISCOUNT UNLOCKED!`}</DiscountText>
      <DiscountTextContainer>
        <Text>Try 7 days for free</Text>
      </DiscountTextContainer>
      <SegmentText>
        then{" "}
        <s>
          {currentPrice.currency.symbol}
          {currentPrice.price}
        </s>{" "}
        {currentPrice.currency.symbol}
        {currentPrice.discounted_price} per month (billed annually){" "}
      </SegmentText>
    </PrePaymentVariantContainer>
  );
};

const PrePaymentVariantContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 468px;
`;

const DiscountText = styled(AppText as any)`
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  color: #ff6145;
  margin-bottom: 64px;
  text-align: center;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 40px;
  }
`;

const DiscountTextContainer = styled.div`
  background-color: #e8f1d2;
  padding: 8px 16px;
  margin-bottom: 10px;
  @media ${mobileSize} {
    font-size: 18px;
    line-height: 18px;
  }
`;

const Text = styled(AppText as any)`
  font-size: 36px;
  line-height: 43px;
  text-align: center;
  font-weight: 500;
  margin: 0;
  color: ${theme.PRIMARY_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
    line-height: 28px;
  }
`;

interface SegmentTextProps {
  color?: string;
  size?: number;
  mobileSize?: number;
  weight?: number;
}

const SegmentText = styled.p<SegmentTextProps>`
  margin: 0;
  text-align: center;
  font-size: ${props => props.size ?? 36}px;
  font-family: ${theme.SECONDARY_FONT};
  line-height: 43px;
  color: ${props => props.color ?? theme.BLACK_COLOR};
  font-weight: 400;
  @media ${mobileSize} {
    font-size: ${props => props.mobileSize ?? 24}px;
    line-height: ${props => (props.mobileSize ? props.mobileSize + 6 : 28)}px;
  }
`;

export default PrePaymentVariant;
