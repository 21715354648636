import { PrePaymentBell } from "../assets";

export enum PREPAYMENT_VIEW_TYPES {
  Animation,
  Text,
}
export type DataT = AnimationT | TextT;

type AnimationT = {
  id: string;
  type: PREPAYMENT_VIEW_TYPES.Animation;
  title?: string;
  asset: string;
};

type TextT = {
  id: string;
  type: PREPAYMENT_VIEW_TYPES.Text;
  title: string;
  background: string;
};

export const prePaymentViews: DataT[] = [
  {
    id: "weOffer7DaysFree",
    type: PREPAYMENT_VIEW_TYPES.Text,
    title:
      "We offer <strong style='color: #20614C'>7 days free</strong> so everyone can learn on Imprint.",
    background: "#20614C",
  },
  {
    id: "trialReminder",
    type: PREPAYMENT_VIEW_TYPES.Animation,
    title:
      "You'll get a reminder <strong style='color: #20614C'>2 days before</strong> your trial ends.",
    asset: PrePaymentBell,
  },
];
