import React from "react";
import styled from "styled-components";
import { AppText } from "../../../ui";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";

interface TextVariantProps {
  title: string;
}

const TextVariant: React.FC<TextVariantProps> = ({ title }) => {
  return (
    <TextVariantContainer>
      {title && <Text dangerouslySetInnerHTML={{ __html: title }} />}
    </TextVariantContainer>
  );
};

const TextVariantContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const Text = styled(AppText as any)`
  font-size: 36px;
  max-width: 468px;
  min-height: 84px;
  line-height: 44px;
  text-align: center;
  margin: 0;
  color: ${theme.BLACK_COLOR};
  b {
    color: ${theme.PRIMARY_COLOR};
  }

  @media ${mobileSize} {
    font-size: 32px;
    line-height: 38px;
  }
`;

export default TextVariant;
